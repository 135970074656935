import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import NoteIcon from "@mui/icons-material/Note";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";

import { StyledFab } from "components/StyledFab";
import { formatDateTime, isEmpty } from "helpers";
import { WorkNoteType } from "operations/schema/schema";

import { NoteDialog } from "pages/jobs/dialogs/NoteDialog";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectNoteDialogOpen,
  setOpen as setOpenNoteDialog,
} from "store/slices/dialogs/note.store";
import { selectWorkNotes } from "store/slices/jobs.store";
import { TabProps } from "./TabProps";

export const NotesTab: FC<TabProps> = (props) => {
  const { setFab } = props;
  const dispatch = useAppDispatch();
  const workNotes = useAppSelector(selectWorkNotes, shallowEqual);
  const openNoteDialog = useAppSelector(selectNoteDialogOpen);

  const getCardHeader = (wn: WorkNoteType) => {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          {wn.isAlert && <PriorityHighIcon color="error" />}
          {wn.isInternal && <LockIcon color="error" />}
        </Grid>
        {!wn.hideHeader && (
          <Grid item>
            <Typography fontWeight={700}>{wn.loggedBy}</Typography>
            <Typography color="text.secondary">{formatDateTime(wn.loggedDate)}</Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  useEffect(() => {
    const fab = (
      <StyledFab
        onClick={() => dispatch(setOpenNoteDialog({ open: true }))}
        data-testid="notesTabOpenDialog"
      >
        <AddIcon />
      </StyledFab>
    );
    setFab(fab);
  }, [dispatch, setFab]);

  return (
    <Grid container direction="column" data-testid="NotesTab">
      {!isEmpty(workNotes) ? (
        workNotes.map((wn, index) => {
          return (
            wn && (
              <Card key={`${index}-${wn.loggedBy}-${wn.loggedDate}`} sx={{ width: "100%" }}>
                <CardHeader
                  title={getCardHeader(wn)}
                  sx={{ pb: 0 }}
                  titleTypographyProps={{ fontSize: 17 }}
                />
                <CardContent sx={{ pl: 3, pr: 3, pt: 1, pb: "1 !important" }}>
                  <Typography align="justify" component="div" whiteSpace="pre-line">
                    <ReactMarkdown children={wn.text} className="markdown-text" />
                  </Typography>
                </CardContent>
              </Card>
            )
          );
        })
      ) : (
        <Grid container item direction="column" alignItems="center" mt={2.5}>
          <Grid item>
            <NoteIcon color="secondary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography color="secondary">
              <FormattedMessage id="note.noNotesFound" />
            </Typography>
          </Grid>
        </Grid>
      )}
      {openNoteDialog && <NoteDialog />}
    </Grid>
  );
};
