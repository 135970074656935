import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { LinearProgressProps } from "@mui/material/LinearProgress";
import { StyledChip } from "components/StyledChip";
import { StyledFab } from "components/StyledFab";
import { ChecklistDialog } from "components/job/checklist/ChecklistDialog";
import { getChecklistStatus } from "helpers";
import { ChecklistType } from "operations/schema/schema";
import { AddChecklistDialog } from "pages/jobs/dialogs/AddChecklistDialog";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectSelectedJobVisit,
  selectVisitStarted,
  setAddChecklistOpen,
} from "store/slices/jobs.store";
import { TabProps } from "./TabProps";

const LinearProgressWithLabel = (props: LinearProgressProps & { label: string }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 2 }}>
        <LinearProgress {...props} />
      </Box>
      <Box sx={{ minWidth: 50 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          {props.label}
        </Typography>
      </Box>
    </Box>
  );
};
interface ChecklistTabProps extends TabProps {
  addButton?: boolean;
}
export const ChecklistTab: FC<ChecklistTabProps> = (props) => {
  const { addButton, setFab } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { checklists } = useAppSelector(selectSelectedJobVisit);
  const [openChecklistDialog, setOpenChecklistDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const visitStarted = useAppSelector(selectVisitStarted);
  const { open: openAddChecklistDialog } = useAppSelector((s) => s.jobs.addChecklist);

  const readonlyChecklist = () => {
    if (selectedIndex === null) return true;
    if (checklists[selectedIndex].hasPreStart) return visitStarted;
    else return !visitStarted;
  };

  const getChecklistElement = (checklist: ChecklistType) => {
    const { isComplete, questionsAnswered, questionsCount } = getChecklistStatus(checklist);

    let labelElement: JSX.Element;

    if (isComplete) {
      labelElement = (
        <Typography color="success.main" display="inline">
          <FormattedMessage id="checklist.complete" />
        </Typography>
      );
    } else if (checklist.isTemplate) {
      labelElement = (
        <Typography display="inline">
          <FormattedMessage id="checklist.begin" />
        </Typography>
      );
    } else {
      labelElement = (
        <Typography color="info.main" display="inline">
          <FormattedMessage id="checklist.inProgress" />
        </Typography>
      );
    }

    const percentage = isComplete ? 100 : (questionsAnswered / questionsCount) * 100;

    const countLabel = `${questionsAnswered}/${questionsCount}`;

    return {
      labelElement,
      percentage,
      countLabel,
    };
  };

  useEffect(() => {
    const fab = addButton ? (
      <StyledFab
        onClick={() => dispatch(setAddChecklistOpen({ open: true }))}
        data-testid="checklistTabOpenAddChecklist"
      >
        <AddIcon />
      </StyledFab>
    ) : undefined;
    setFab(fab);
  }, [addButton, dispatch, setFab]);

  return (
    <Container>
      <List>
        {checklists.map(({ checklist, hasPreStart }, index, { length }) => {
          const { labelElement, percentage, countLabel } = getChecklistElement(checklist);
          return (
            <div key={checklist.checklistCode}>
              <ListItem
                key={checklist.title}
                disablePadding
                onClick={() => {
                  setSelectedIndex(index);
                  setOpenChecklistDialog(true);
                }}
                disableGutters
                secondaryAction={
                  <IconButton sx={{ padding: 0 }}>
                    {labelElement}
                    <KeyboardArrowRightIcon />
                  </IconButton>
                }
                data-testid={`ChecklistTab-ListItem-${checklist.checklistCode}`}
              >
                <ListItemButton disableGutters sx={{ maxWidth: "80%" }}>
                  <ListItemText primary={checklist.title} />
                  {hasPreStart && (
                    <StyledChip label={intl.formatMessage({ id: "checklist.preWork" })} />
                  )}
                </ListItemButton>
              </ListItem>
              <LinearProgressWithLabel
                color="success"
                variant="determinate"
                value={percentage}
                label={countLabel}
                sx={{ height: 10, backgroundColor: "secondary.light" }}
              />
              {index + 1 !== length && <Divider sx={{ mt: 1 }} />}
            </div>
          );
        })}
      </List>
      {openAddChecklistDialog && <AddChecklistDialog />}
      <ChecklistDialog
        open={openChecklistDialog}
        handleClose={() => {
          setOpenChecklistDialog(false);
          setSelectedIndex(null);
        }}
        index={selectedIndex!}
        checklist={selectedIndex !== null ? checklists[selectedIndex].checklist : null}
        readonly={readonlyChecklist()}
      />
    </Container>
  );
};
