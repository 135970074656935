import { Tab, Tabs, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { TabPanel } from "components/TabPanel";
import { useAppSelector } from "store";
import { selectSelectedJobVisit, selectTravelTabInvalid } from "store/slices/jobs.store";
import { TabProps } from "./TabProps";
import { TravelTab } from "./times/TravelTab";
import { WorkTab } from "./times/WorkTab";

const StyledToolbarPlaceholder = styled(Toolbar)(() => ({
  padding: 0,
  zIndex: -1,
}));

const errorKeys = {
  travel: ["travelTimes"],
  work: ["workTimes"],
};

export const TimesTab: FC<TabProps> = (props) => {
  const { setFab } = props;
  const intl = useIntl();
  const [tabValue, setTabValue] = useState("travel");
  const { errors } = useAppSelector(selectSelectedJobVisit);
  const travelTabInvalid = useAppSelector(selectTravelTabInvalid);

  useEffect(() => {
    const fab = undefined;
    setFab(fab);
  }, [setFab]);

  const tabHasError = (tabName: keyof typeof errorKeys) => {
    let searchKeys = errorKeys[tabName];
    let currentErrorKeys = Object.keys(errors);
    for (let key of currentErrorKeys) {
      if (searchKeys.some((sub) => key.includes(sub))) return true;
    }
    return false;
  };

  return (
    <>
      <Tabs
        data-testid="TimesTabTabs"
        scrollButtons
        value={tabValue}
        onChange={(_, newValue: string) => {
          setTabValue(newValue);
        }}
        variant="fullWidth"
        textColor="inherit"
        style={{
          position: "fixed",
          background: "white",
          width: "100%",
          zIndex: 10,
        }}
      >
        <Tab
          label={
            <>
              {intl.formatMessage({ id: "visit.travel" })}
              {tabHasError("travel") || travelTabInvalid ? "*" : null}
            </>
          }
          value="travel"
        />
        <Tab
          label={
            <>
              {intl.formatMessage({ id: "visit.work" })}
              {tabHasError("work") ? "*" : null}
            </>
          }
          value="work"
        />
      </Tabs>
      <StyledToolbarPlaceholder />
      <TabPanel index="travel" value={tabValue}>
        <TravelTab />
      </TabPanel>
      <TabPanel index="work" value={tabValue}>
        <WorkTab />
      </TabPanel>
    </>
  );
};
