import { Build as BuildIcon } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyledChips } from "components/StyledChips";
import StyledTextFieldReadOnly from "components/StyledTextFieldReadOnly";
import Contacts from "components/job/details/Contacts";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { CustomerDisplay } from "./jobDetailTab/CustomerDisplay";
import { DetailsTimeDisplay } from "./jobDetailTab/DetailsTimeDisplay";
import { EquipmentDisplay } from "./jobDetailTab/EquipmentDisplay";
import { JobDetailsActions } from "pages/jobs/jobDetails/JobDetailsActions";

const PREFIX = "JobDetailTab";

const classes = {
  symptomDescription: `${PREFIX}-symptomDescription`,
  bold: `${PREFIX}-bold`,
  rightIconAdjust: `${PREFIX}-right-icon-adjust`,
  leftIconAdjust: `${PREFIX}-left-icon-adjust`,
  historyIconAdjust: `${PREFIX}-eq-history-icon-adjust`,
  iconAutoMargin: `${PREFIX}-icon-auto-margin`,
  iconInfo: `${PREFIX}-icon-info`,
  noPadding: `${PREFIX}-no-padding`,
  leftIndent: `${PREFIX}-left-indent`,
  disabled: `${PREFIX}-disabled`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.bold}`]: {
    fontWeight: 700,
  },

  [`& .${classes.rightIconAdjust}`]: {
    position: "relative",
    top: "0.2em",
    left: "0.2em",
  },
  [`& .${classes.leftIconAdjust}`]: {
    position: "relative",
    top: "0.2em",
  },
  [`& .${classes.historyIconAdjust}`]: {
    color: theme.palette.info.main,
    position: "relative",
    bottom: "0.1rem",
    marginRight: "0.2rem",
  },
  [`& .${classes.iconAutoMargin}`]: {
    margin: "auto",
  },
  [`& .${classes.iconInfo}`]: {
    color: theme.palette.info.main,
  },
  [`& .${classes.noPadding}`]: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  [`& .${classes.leftIndent}`]: {
    marginLeft: "38px",
  },
  [`& .${classes.disabled}`]: {
    color: theme.palette.secondary.main,
  },
}));

interface JobDetailTabProps {
  planner?: boolean;
}

export const JobDetailTab: FC<JobDetailTabProps> = (props: JobDetailTabProps) => {
  const { planner } = props;
  const intl = useIntl();
  const { pathname } = useLocation();
  const job = useAppSelector(selectPageSelectedJob);
  const backTo = pathname.split("/").filter((x) => x)[0];
  const contacts = job.customer?.contacts || [];

  return (
    <>
      <StyledCard elevation={1} data-testid="JobDetailTab">
        <CardHeader
          sx={{
            fontSize: "1.2rem",
          }}
          title={
            <>
              <DetailsTimeDisplay readonly={planner} />
              <CustomerDisplay />
            </>
          }
          subheader={
            <>
              <EquipmentDisplay readonly={planner} />

              {backTo === "planner" && (
                <ListItem component="div" disableGutters className={classes.noPadding}>
                  <ListItemIcon className="min-width-38">
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.bold}
                    data-testid="JobDetailsTab-AssignedTo"
                    primary={
                      !!job.engineerName
                        ? `${intl.formatMessage({ id: "visit.assignedTo" })} ${job.engineerName}`
                        : intl.formatMessage({ id: "general.unassigned" })
                    }
                  />
                </ListItem>
              )}
            </>
          }
        />
        <CardContent style={{ paddingTop: 0 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {job.chargeBand && (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "1.2rem",
                    }}
                    color="black"
                  >
                    <FormattedMessage id="visit.serviceTerms" />
                  </Typography>
                  <List dense disablePadding>
                    {job.chargeBand && (
                      <ListItem disableGutters>
                        <ListItemText primary={job.chargeBand} sx={{ marginLeft: "38px" }} />
                      </ListItem>
                    )}
                  </List>
                </>
              )}
            </Grid>
            <Grid item sx={{ pt: "4px !important" }}>
              <Contacts
                customerAddress={job.customer?.address!}
                contacts={contacts}
                preferredContact={job.preferredContact}
                readOnly={false}
              />
            </Grid>
            <Grid item>
              <StyledChips
                isOnStop={job.customer?.isOnStop}
                category={job.category}
                priority={job.priority}
                symptoms={job.symptoms}
              />
            </Grid>
            {job.symptomDescription && (
              <Grid item>
                <StyledTextFieldReadOnly
                  label={intl.formatMessage({ id: "job.symptomDescription" })}
                  multiline
                  minRows={15}
                  defaultValue={job?.symptomDescription}
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </StyledCard>
      <JobDetailsActions />
    </>
  );
};
