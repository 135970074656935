import {
  Build as BuildIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Print as PrintIcon,
} from "@mui/icons-material";
import { CircularProgress, SwipeableDrawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import StyledFabFixed from "components/StyledFabFixed";
import { CreateJobDialog } from "pages/jobs/dialogs/CreateJobDialog";
import { EquipmentEditDialog } from "pages/jobs/dialogs/EquipmentEditDialog";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus } from "store/root.store";
import {
  selectCreateJobOpen,
  setOpen as setOpenCreateJobDialog,
} from "store/slices/dialogs/createJob.store";
import {
  selectIsJobInProgress,
  selectSelectedJob,
  setChangeJobEquipmentOpen,
  setRejectJobOpen,
  setUpdateEquipmentOpen,
} from "store/slices/jobs.store";
import { isFlagEnabled, selectEngineerSettings } from "store/slices/user.store";
import { RejectJobDialog } from "./RejectJobDialog";

const StyledContainer = styled("div")(() => ({
  zIndex: "9001",
}));

export const JobDetailsActions: FC = () => {
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(selectConnectionStatus);
  const job = useAppSelector(selectSelectedJob);
  const openCreateJobDialog = useAppSelector(selectCreateJobOpen);
  const engineerSettings = useAppSelector(selectEngineerSettings);

  const canCreateJob = engineerSettings?.canCreateJob && isOnline;
  const changeJobEquipmentEnabled = useAppSelector((s) => isFlagEnabled(s, "ChangeJobEquipment"));
  const canChangeEquipment =
    engineerSettings?.canChangeEquipmentOnTicket && isOnline && changeJobEquipmentEnabled;
  const [loading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const jobInProgress = useAppSelector(selectIsJobInProgress);

  return (
    <StyledContainer data-testid="jobDetailsActionsContainer">
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <StyledFabFixed
        disabled={loading}
        onClick={() => setOpenDrawer(true)}
        data-testid="jobDetailsActionsOpenButton"
      >
        <MoreVertIcon />
      </StyledFabFixed>
      <SwipeableDrawer
        anchor="bottom"
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
      >
        <div style={{ margin: "0px 16px" }} data-testid="jobDetailsActionsDrawer">
          {!jobInProgress && (
            <PrimaryButton
              data-testid="rejectJobButton"
              onClick={() => dispatch(setRejectJobOpen({ open: true }))}
              startIcon={<CancelIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="general.rejectJob" />
            </PrimaryButton>
          )}
          {canCreateJob && (
            <PrimaryButton
              data-testid="createJobButton"
              onClick={() => dispatch(setOpenCreateJobDialog({ open: true }))}
              startIcon={<BuildIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="menu.createNewJob" />
            </PrimaryButton>
          )}
          {canChangeEquipment && (
            <PrimaryButton
              data-testid="changeJobEquipmentButton"
              onClick={() => dispatch(setChangeJobEquipmentOpen({ open: true }))}
              startIcon={<EditIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="visit.changeMachine" />
            </PrimaryButton>
          )}
          {jobInProgress && job.equipment?.id && (
            <PrimaryButton
              data-testid="equipmentEditButton"
              onClick={() => dispatch(setUpdateEquipmentOpen({ open: true }))}
              startIcon={<PrintIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="visit.editMachineLocation" />
            </PrimaryButton>
          )}
        </div>
      </SwipeableDrawer>
      {canCreateJob && openCreateJobDialog && <CreateJobDialog customer={job.customer} />}
      {job.equipment && <EquipmentEditDialog />}
      <RejectJobDialog />
    </StyledContainer>
  );
};
